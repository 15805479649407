import styled, { ThemeContext } from 'styled-components'

import confetti from 'canvas-confetti'
import { sample } from 'lodash-es'
import { FunctionComponent, memo, useCallback, useContext, useEffect, useMemo } from 'react'

import { logError } from '~/src/utils/errors'
import sleep from '~/src/utils/sleep'

const CONFETTI_ELEM_ID = 'full_screen_confetti'

type Props = {
  onFinish?: () => void
}

const FixedConfetti = styled.div`
  position: fixed;
  overflow: visible;
  pointer-events: none;
  width:100%;
  height 100%;
  top: 0;
  left: 0;
  zindex: ${({ theme }) => theme.ryuStackingLevels.overpowered};
`

export const FullScreenConfetti: FunctionComponent<Props> = memo(({ onFinish }) => {
  const { colors } = useContext(ThemeContext)

  const confettiColors = useMemo(() => [colors.yellow, colors.lightGold1, colors.lightGold3], [colors])
  const pickRandomColor = useCallback(() => sample(confettiColors)!, [confettiColors])
  const triggerOnFinish = useCallback(() => onFinish?.(), [onFinish])

  useEffect(() => {
    ;(async () => {
      let confettiEl = document.getElementById(CONFETTI_ELEM_ID)
      while (!confettiEl) {
        await sleep(500)
        confettiEl = document.getElementById(CONFETTI_ELEM_ID)
      }

      const myCanvas = document.createElement('canvas')
      myCanvas.width = window.innerWidth
      myCanvas.height = window.innerHeight
      if (!confettiEl) {
        logError.high('No confetti canvas elem found', {
          tags: { owner: 'cktang88' },
        })

        return
      }

      confettiEl.appendChild(myCanvas)

      const myConfetti = confetti.create(myCanvas, {
        resize: true,
        useWorker: true,
      })

      const duration = 3 * 1000
      const animationEnd = Date.now() + duration
      let skew = 1

      function randomInRange(min: number, max: number) {
        return Math.random() * (max - min) + min
      }

      ;(function frame() {
        const timeLeft = animationEnd - Date.now()
        const ticks = Math.max(200, 500 * (timeLeft / duration))
        skew = Math.max(0.8, skew - 0.001)

        myConfetti({
          particleCount: 1,
          startVelocity: 0,
          ticks,
          // @ts-ignore
          gravity: 0.5,
          origin: {
            x: Math.random(),
            // since particles fall down, skew start toward the top
            y: Math.random() * skew - 0.2,
          },
          colors: [pickRandomColor()],
          shapes: ['circle'],
          scalar: randomInRange(0.4, 1),
        })

        if (timeLeft > 0) {
          requestAnimationFrame(frame)
        } else {
          triggerOnFinish()
        }
      })()
    })()
  }, [pickRandomColor, triggerOnFinish])

  return <FixedConfetti id={CONFETTI_ELEM_ID} />
})
