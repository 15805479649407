import { RyuBreak, RyuButton, RyuGrid, RyuText } from '@ramp/ryu'
import { FunctionComponent, useMemo } from 'react'
import styled from 'styled-components'
import { KenRampLogo } from '~/src/components/ken/KenRampLogo'
import contactUsSuccess from '~/src/images/contact-us-success.jpeg'
import { ContactUsStatus } from './ContactUsSurveyWrapper'

const FullHeightGridColored = styled(RyuGrid)`
  height: 100%;
  background-color: #fff;
  color: #2e2e27;
`

const LeftSide = styled.div`
  padding: 72px 64px;
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 768px) {
    padding: 48px 36px;
  }
`

const SizedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
`

const ImageContainer = styled.div`
  height: 100%;
  padding: 24px;
  @media (max-width: 768px) {
    display: none;
  }
`

interface ContactUsThankYouPageProps {
  status: ContactUsStatus
}

const ContactUsThankYouPage: FunctionComponent<ContactUsThankYouPageProps> = ({ status }) => {
  const { titleCopy, detailCopy, buttonCopy, href } = useMemo(() => {
    switch (status) {
      case ContactUsStatus.SUBMITTED_WITHOUT_MEETING:
        return {
          titleCopy: 'Thank you!',
          detailCopy: 'We will get back to you within 1 business day.',
          buttonCopy: 'Apply to Ramp',
          href: '/sign-up',
        }
      case ContactUsStatus.BOOKED_MEETING:
        return {
          titleCopy: 'Thank you for booking time!',
          detailCopy: null,
          buttonCopy: 'Apply to Ramp',
          href: '/sign-up',
        }
      case ContactUsStatus.INELIGIBLE:
        return {
          titleCopy: 'We might not be a good fit for your company yet.',
          detailCopy: (
            <span>
              If you are a new company in the process of raising capital, or you’d like to provide
              additional documentation for our team to review, please{' '}
              <a href='/sign-up'>start an application</a>.
            </span>
          ),
          buttonCopy: 'Back to home',
          href: '/',
        }
      default:
        return {
          titleCopy: 'Thank you!',
          detailCopy: 'We will get back to you within 1 business day.',
          buttonCopy: 'Apply to Ramp',
          href: '/sign-up',
        }
    }
  }, [status])
  return (
    <FullHeightGridColored columns={2} responsiveColumns={1}>
      <LeftSide>
        <KenRampLogo />
        <RyuBreak size='xl' />
        <RyuText.h2 paddingVertical='none'>{titleCopy}</RyuText.h2>
        <RyuBreak size='s' />
        <RyuText.p color='hushed'>{detailCopy}</RyuText.p>
        <RyuButton
          href={href}
          size='m'
          color='accent'
          variant='prime'
          fullWidth={true}
          _textAlign='center'
        >
          {buttonCopy}
        </RyuButton>
      </LeftSide>
      <ImageContainer>
        <SizedImage src={contactUsSuccess} alt='Contact us success hero' />
      </ImageContainer>
    </FullHeightGridColored>
  )
}

export default ContactUsThankYouPage
