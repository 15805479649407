import '~/src/styles/globalStyles.css'
import { StrictMode } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { isDevelopment, isQA, isDemo } from '~/src/utils/env'
import { RyuProvider, setupRyu, RyuProviderProps } from '@ramp/ryu'
import { createRoot } from 'react-dom/client'
import App from './App'
import { theme } from '~/src/styles/theme'
import { ThemeProvider, DefaultTheme } from 'styled-components'
import { init as initLogrocket } from '~/src/utils/integrations/logrocket'
import { init as initSentry } from '~/src/utils/integrations/sentry'
import { init as initFirebase } from '~/src/utils/integrations/firebase'
import { installUserDataWiper } from '~/src/utils/analytics'
import { installPageEnrichmentForAdTracking } from './utils/analytics/pageEnrichment'
import { logError } from '~/src/utils/errors'
import { Router } from 'react-router-dom'
import { history } from '~/src/utils/history'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

const appRootElement = document.getElementById('root')!
const root = createRoot(appRootElement)
setupRyu({ appRootElement })

initSentry()
initFirebase()
initLogrocket()
installUserDataWiper()
window.analytics.ready(() => {
  installPageEnrichmentForAdTracking().then(() => {
    window.analytics.page({})
  })
})

const errorConfig: RyuProviderProps['errorConfig'] = {
  stackTraces: isDevelopment || isQA || isDemo,
  onError: (error) => logError.high(error),
}

root.render(
  <StrictMode>
    <HelmetProvider>
      <RyuProvider errorConfig={errorConfig}>
        <ThemeProvider theme={theme as DefaultTheme}>
          <QueryClientProvider client={queryClient}>
            <Router history={history}>
              <App />
            </Router>
          </QueryClientProvider>
        </ThemeProvider>
      </RyuProvider>
    </HelmetProvider>
  </StrictMode>
)
