import { unauthedApi as api } from '~/src/api'
import { ApiLeadModel, LeadModel } from './types'
import { useCallback } from 'react'

export function useCreateLeadModel(): (lead: Record<string, string>) => Promise<ApiLeadModel> {
  return useCallback((lead: Record<string, string>): Promise<ApiLeadModel> => {
    return api.post('leads/async', { json: lead }).json()
  }, [])
}

export function useUpdateLeadModel(): (
  leadId: string | undefined,
  lead: Partial<LeadModel>
) => Promise<ApiLeadModel> {
  return useCallback(async (leadId, lead): Promise<ApiLeadModel> => {
    await api.patch(`leads/${leadId}`, { json: lead }).json()
    const leadResponse = await api.get(`leads/${leadId}`).json()
    return leadResponse as ApiLeadModel
  }, [])
}

export function useFetchLeadModel(): (leadId: string) => Promise<ApiLeadModel> {
  return useCallback(async (leadId: string) => {
    const response = await api.get(`leads/${leadId}`).json()
    return response as ApiLeadModel
  }, [])
}
