import styled from 'styled-components'

import { RyuFlex, RyuText } from '@ramp/ryu'

import g2Logo from '~/src/images/logos/g2-logo.svg'
import capeterraLogo from '~/src/images/logos/capterra-logo.svg'
import getAppLogo from '~/src/images/logos/getapp-logo.svg'
import softwareAdviceLogo from '~/src/images/logos/software-advice-logo.svg'

const ReviewTestimonialContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  grid-auto-columns: minmax(0, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const ReviewItemContainer = styled.div`
  padding: 1rem 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;

  border: ${({ theme }) => theme.borders.outline};
  border-radius: ${({ theme }) => theme.ryuSpacingSizes.s}rem;

  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
`

export function ReviewTestimonial() {
  return (
    <ReviewTestimonialContainer>
      <ReviewItemContainer>
        <img src={g2Logo} width='20' height='20' alt='Reviewer' />
        <RyuFlex direction='column' gapSize='none' alignItems='center'>
          <RyuText.p size='s' align='center' ellipsis={true}>
            4.8 Stars
          </RyuText.p>
          <RyuText.p size='xs' align='center' ellipsis={true}>
            G2
          </RyuText.p>
        </RyuFlex>
      </ReviewItemContainer>
      <ReviewItemContainer>
        <img src={capeterraLogo} width='20' height='20' alt='Reviewer' />
        <RyuFlex direction='column' gapSize='none' alignItems='center'>
          <RyuText.p size='s' align='center' ellipsis={true}>
            4.8 Stars
          </RyuText.p>
          <RyuText.p size='xs' align='center' ellipsis={true}>
            Capterra
          </RyuText.p>
        </RyuFlex>
      </ReviewItemContainer>
      <ReviewItemContainer>
        <img src={getAppLogo} width='20' height='20' alt='Reviewer' />
        <RyuFlex direction='column' gapSize='none' alignItems='center'>
          <RyuText.p size='s' align='center' ellipsis={true}>
            4.9 Stars
          </RyuText.p>
          <RyuText.p size='xs' align='center' ellipsis={true}>
            GetApp
          </RyuText.p>
        </RyuFlex>
      </ReviewItemContainer>
      <ReviewItemContainer>
        <img src={softwareAdviceLogo} width='20' height='20' alt='Reviewer' />
        <RyuFlex direction='column' gapSize='none' alignItems='center'>
          <RyuText.p size='s' align='center' ellipsis={true}>
            4.9 Stars
          </RyuText.p>
          <RyuText.p size='xs' align='center' ellipsis={true}>
            Software Advice
          </RyuText.p>
        </RyuFlex>
      </ReviewItemContainer>
    </ReviewTestimonialContainer>
  )
}
