import { useRyuBreakpoints } from '@ramp/ryu'
import styled from 'styled-components'
import { TalkToSalesButton } from './ChatIconWithGreenDot'

export const TalkToSalesDesktopButton = () => {
  const isTablet = useRyuBreakpoints({ maxWidth: 'tablet' })

  if (isTablet) {
    return null
  }

  return (
    <TalkToSalesDesktop>
      <TalkToSalesButton />
    </TalkToSalesDesktop>
  )
}

const TalkToSalesDesktop = styled.div`
  position: fixed;
  bottom: ${({ theme }) => theme.ryuSpacing[5]}rem;
  right: ${({ theme }) => theme.ryuSpacing[5]}rem;
  z-index: ${({ theme }) => theme.ryuStackingLevels.modest};
`
