import { useMemo } from 'react'

import { RoutingDirection, LeadModel, PreApplicationStep } from '~/src/models/leads/LeadModel'

import { LESS_THAN_50k } from './pages/GLA'

export const preApplicationSteps = [null, ...Object.values(PreApplicationStep)]

export const getPreApplicationRouteName = (step: PreApplicationStep | null) => {
  switch (step) {
    case null:
      return 'company-size'
    case PreApplicationStep.COMPANY_SIZE:
      return 'company-size'
    case PreApplicationStep.MONTHLY_SPEND:
      return 'monthly-spend'
    case PreApplicationStep.GLA:
      return 'gla'
    case PreApplicationStep.UNQUALIFIED:
      return 'demo-only'
    case PreApplicationStep.SALES_QUALIFIED:
      return 'contact-sales'
    case PreApplicationStep.INTERESTED_PRODUCTS:
      return 'interested-products'
    // This is in web
    case PreApplicationStep.DEMO_REQUESTED:
      return 'demo-requested'
    // This is in web
    case PreApplicationStep.SELF_SERVICE_QUALIFIED:
      return 'self-service'
  }
}

export const comparePreApplicationSteps = (
  step1: PreApplicationStep | null,
  step2: PreApplicationStep | null
) => preApplicationSteps.indexOf(step1) - preApplicationSteps.indexOf(step2)

export const useCurrentStep = (overriding_to_sales: boolean, lead: LeadModel | null) => {
  const shouldSkipPrequal = Boolean(lead?.skipped_prequal)

  return useMemo(() => {
    if (overriding_to_sales) {
      return PreApplicationStep.SALES_QUALIFIED
    }

    if (!lead) {
      return PreApplicationStep.COMPANY_SIZE
    }

    const interested_in_nothing =
      !lead.interested_in_bill_pay &&
      !lead.interested_in_corporate_cards &&
      !lead.interested_in_reimbursements &&
      !lead.interested_in_flex &&
      !lead.interested_in_procurement

    if (!shouldSkipPrequal) {
      if (!lead.has_employee_count) {
        return PreApplicationStep.COMPANY_SIZE
      }

      if (!lead.has_estimated_monthly_spend) {
        return PreApplicationStep.MONTHLY_SPEND
      }

      if (!lead.has_bank_balance) {
        return PreApplicationStep.GLA
      }

      if (
        lead.bank_balance === LESS_THAN_50k ||
        lead.routing_direction === RoutingDirection.UNQUALIFIED
      ) {
        return PreApplicationStep.UNQUALIFIED
      }

      if (interested_in_nothing) {
        return PreApplicationStep.INTERESTED_PRODUCTS
      }
    }

    if (lead.requested_demo) {
      return PreApplicationStep.DEMO_REQUESTED
    }

    if (lead?.routing_direction === RoutingDirection.SALES_QUALIFIED) {
      return PreApplicationStep.SALES_QUALIFIED
    }

    return PreApplicationStep.SELF_SERVICE_QUALIFIED
  }, [lead, overriding_to_sales, shouldSkipPrequal])
}
