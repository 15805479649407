import eightSleep from '../images/carousel-logos/8Sleep.svg'
import eightOneEight from '../images/carousel-logos/818.svg'
import anduril from '../images/carousel-logos/Anduril.svg'
import appliedIntuition from '../images/carousel-logos/AppliedIntuition.svg'
import avelo from '../images/carousel-logos/Avelo.svg'
import clickUp from '../images/carousel-logos/ClickUp.svg'
import faire from '../images/carousel-logos/Faire.svg'
import madhappy from '../images/carousel-logos/Madhappy.svg'
import marqeta from '../images/carousel-logos/Marqeta.svg'
import roman from '../images/carousel-logos/Roman.svg'
import shortcut from '../images/carousel-logos/Shortcut.svg'
import styled from 'styled-components'

const images = [
  madhappy,
  eightOneEight,
  faire,
  shortcut,
  anduril,
  roman,
  clickUp,
  marqeta,
  eightSleep,
  appliedIntuition,
  avelo,
]

interface ImageCarouselProps {
  hideOnMobile?: boolean
}

const FullWidthWrappingFlex = styled.div<ImageCarouselProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
  column-gap: 32px;
  row-gap: 24px;
  align-items: center;
  max-width: 1024px;
  ${(props) =>
    props.hideOnMobile &&
    `
    @media (max-width: 768px) {
    display: none;
  }

  `}
`

function ImageCarousel({ hideOnMobile }: ImageCarouselProps) {
  return (
    <FullWidthWrappingFlex hideOnMobile={hideOnMobile}>
      {images.map((image, index) => (
        <img key={index} src={image} alt={`carousel logo ${index}`} />
      ))}
    </FullWidthWrappingFlex>
  )
}

export default ImageCarousel
