import { RyuGrid, RyuText } from '@ramp/ryu'

import { KenFieldPassword } from '~/src/components/ken/KenForm/KenFieldPassword'
import { KenFieldText } from '~/src/components/ken/KenForm/KenFieldText'
import { KenStack } from '~/src/components/ken/KenStack'
type SignUpFormFields = any
type UseSignUpResponse = any

function getNameValidationRules(
  fieldName: string,
  { required } = { required: true }
): {
  required?: string
  pattern: { value: RegExp; message: string }
  maxLength: { value: number; message: string }
} {
  return {
    ...(required && { required: `${fieldName} is required` }),
    pattern: {
      value: /[a-zA-Z]/,
      message: `${fieldName} must contain at least one letter`,
    },
    maxLength: {
      value: 40,
      message: `${fieldName} must be at most 40 characters`,
    },
  }
}

export function UsernameSignUpFormFields({
  form,
  validateEmail,
  allowPersonalEmails,
  existingBusinessError,
}: Pick<UseSignUpResponse, 'form' | 'validateEmail' | 'allowPersonalEmails' | 'existingBusinessError'>) {
  return (
    <KenStack gapSize='s'>
      <KenFieldText<SignUpFormFields>
        name='email'
        rules={{ validate: (email) => validateEmail({ email, allowPersonalEmails }) }}
        label='Work email address'
        type='email'
        iconType='lock'
        iconSide='right'
        iconTooltipContent='This is the email you are using to apply to Ramp'
        disabled={!!form.formState.defaultValues?.email}
      />

      <RyuGrid columns={2} responsiveColumns={1} gapSize='s'>
        <KenFieldText<SignUpFormFields>
          name='first_name'
          required={true}
          rules={getNameValidationRules('First name')}
          label='First name'
          iconType='info'
          iconSide='right'
          iconTooltipContent='Your legal first name as listed on a driver’s license, passport, etc'
          disabled={!!form.formState.defaultValues?.first_name}
        />

        <KenFieldText<SignUpFormFields>
          name='last_name'
          required={true}
          rules={getNameValidationRules('Last name')}
          label='Last name'
          iconType='info'
          iconSide='right'
          iconTooltipContent='Your legal last name as listed on a driver’s license, passport, etc'
          disabled={!!form.formState.defaultValues?.last_name}
        />
      </RyuGrid>

      <KenFieldPassword<SignUpFormFields> label='Choose a password' name='password' />

      {existingBusinessError && (
        <RyuText.div size='xs' color='destructive'>
          {existingBusinessError}
        </RyuText.div>
      )}
    </KenStack>
  )
}
