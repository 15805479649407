import { Fragment, FunctionComponent } from 'react'

import { RyuBreak, RyuFlex, RyuPane, RyuProgressBar, RyuText } from '@ramp/ryu'

import { RigidPageContent } from '~/src/components/RigidPageContent'

import { BackButton } from '../BackButton'
import { StepHeader } from '../StepHeader'

// These constants are the only values BE will accept.
export const LESS_THAN_50k = 'balance < 50k'
const MORE_THAN_50k = 'balance > 50k'

type Props = {
  onChange: (value: typeof LESS_THAN_50k | typeof MORE_THAN_50k) => void
  onClickedBack: () => void
}

export const GLA: FunctionComponent<Props> = ({ onChange, onClickedBack }) => {
  return (
    <Fragment>
      <RyuProgressBar value={75} size='l' color='accent' />

      <RigidPageContent>
        <RyuText align='center' color='hushed' size='l'>
          Is Ramp right for your business?
        </RyuText>
        <StepHeader>Do you have more than $50,000 in your business bank account?</StepHeader>
        <RyuBreak size='l' />

        <RyuPane
          data-test-id={`${MORE_THAN_50k}-pane`}
          label='Yes, we have $50k or more.'
          paddingSizeHorizontal='xl'
          paddingSizeVertical='l'
          onClick={() => onChange(MORE_THAN_50k)}
        />
        <RyuBreak />
        <RyuPane
          data-test-id={`${LESS_THAN_50k}-pane`}
          label='No, we have less than $50k.'
          paddingSizeHorizontal='xl'
          paddingSizeVertical='l'
          onClick={() => onChange(LESS_THAN_50k)}
        />
        <RyuBreak size='l' />
        <RyuFlex>
          <BackButton onClick={onClickedBack} />
        </RyuFlex>
      </RigidPageContent>
    </Fragment>
  )
}
