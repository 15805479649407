import styled from 'styled-components'

import { RyuBreak, RyuLoadingSpinner } from '@ramp/ryu'

import { UsernameSignUpFormFields } from './UsernameSignUpFormFields'
import { KenForm } from '../ken/KenForm/KenForm'
import { KenFormSubmitButton } from '../ken/KenForm/KenFormSubmitButton'
import { useSignUp } from '~/src/hooks/useSignUp'
import { UserAlreadyExists } from '../UserAlreadyExists'

export function UsernameSignUp(params: any) {
  const { leadEmail } = params
  const {
    leadCheckForExistingIdentityIsLoading,
    allowPersonalEmails,
    form,
    leadEmailMatchesExistingIdentity,
    existingBusinessError,
    onSubmit,
    validateEmail,
  } = useSignUp(params)

  if (leadCheckForExistingIdentityIsLoading) {
    return (
      <SpinnerLayout>
        <RyuLoadingSpinner size='xxl' />
      </SpinnerLayout>
    )
  }

  if (leadEmailMatchesExistingIdentity && leadEmail) {
    return <UserAlreadyExists email={leadEmail} />
  }

  return (
    <FullWidth>
      <KenForm
        form={form}
        render={({ formProps }) => (
          <form {...formProps} noValidate={true}>
            <UsernameSignUpFormFields
              form={form}
              allowPersonalEmails={allowPersonalEmails}
              validateEmail={validateEmail}
              existingBusinessError={existingBusinessError}
            />

            <RyuBreak size='xl' />

            <KenFormSubmitButton color='accent' variant='prime' fullWidth={true} _textAlign='center'>
              Start application
            </KenFormSubmitButton>
          </form>
        )}
        onSubmit={onSubmit}
      />
    </FullWidth>
  )
}

const SpinnerLayout = styled.div`
  height: 30rem;
  width: 30rem;
`

const FullWidth = styled.div`
  width: 100%;
`
