import { isProduction } from './env'

interface ChiliPiperConfig {
  lead: {
    FirstName: string
    LastName: string
    Email: string
  }
  onRouted: () => void
  onSuccess: () => void | Promise<void>
  onClose: () => void
  beforeSubmit?: () => void
  onError?: () => void
}

export function showChilipiper({ beforeSubmit, onError, ...rest }: ChiliPiperConfig) {
  if (isProduction) {
    if (window.ChiliPiper) {
      if (beforeSubmit) {
        beforeSubmit()
      }

      window.ChiliPiper.submit('ramp-com', 'sdr-queue', rest)
    } else if (onError) {
      onError()
    }
  }
}
