import LogRocket from 'logrocket'
import { useCallback, useContext } from 'react'
import CookiesUtil from '~/src/utils/cookies'
import {
  useCreateLeadModel,
  useUpdateLeadModel,
  PreApplicationStep,
  ApiLeadModel,
  LeadContext,
} from '~/src/models/leads/LeadModel'

type CustomSegmentProperties = {
  location?: string
}

export enum BankBalance {
  OVER_50K = 'balance > 50k',
  UNDER_50K = 'balance < 50k',
}

type CustomLeadProperties = {
  discovery_method?: string | null
  first_name?: string
  last_name?: string
  company_name?: string
  employee_count?: string
  estimated_monthly_spend?: string
  bank_balance?: BankBalance
  job_title?: string
  additional_notes?: string
}

type LeadPropertiesInput = {
  email: string
  cookies: { [name: string]: any }
}

const getCurrentTimeUTC = () => {
  const today = new Date()
  const todayISO = today.toISOString()
  const [date, time] = todayISO.split('T')
  const [wholeTime] = time.split('.')
  return `${date} ${wholeTime}`
}

const getLeadProperties = ({ email, cookies }: LeadPropertiesInput) => ({
  company_email: email,
  ajs_anonymous_id_from_web_session: cookies.ajs_anonymous_id,

  initial_utm_campaign: cookies.initial_utm_campaign?.toString() ?? 'Inbound - PreApp Flow',
  initial_utm_campaign_at: cookies.initial_utm_campaign_at?.replace('%20', ' ') ?? getCurrentTimeUTC(),
  initial_utm_source: cookies.initial_utm_source?.toString(),
  initial_utm_medium: cookies.initial_utm_medium?.toString(),
  initial_utm_term: cookies.initial_utm_term,
  initial_utm_content: cookies.initial_utm_content?.toString(),
  initial_promo: cookies.initial_partner_promo ?? cookies.initial_promo,
  initial_referral_code: cookies.initial_rc,
  initial_utm_campaign_id: cookies.initial_utm_campaign_id?.toString(),
  initial_utm_ad_group: cookies.initial_utm_ad_group?.toString(),
  initial_utm_ad_group_id: cookies.initial_utm_ad_group_id?.toString(),
  initial_utm_ad: cookies.initial_utm_ad?.toString(),
  initial_utm_ad_id: cookies.initial_utm_ad_id?.toString(),
  initial_utm_placement: cookies.initial_utm_placement,
  initial_utm_cta_offer_text: cookies.initial_utm_cta_offer_text,

  utm_campaign: cookies.utm_campaign?.toString(),
  utm_campaign_at: cookies.utm_campaign_at?.replace('%20', ' '),
  utm_source: cookies.utm_source?.toString(),
  utm_medium: cookies.utm_medium?.toString(),
  utm_term: cookies.utm_term,
  utm_content: cookies.utm_content?.toString(),
  promo: cookies.partner_promo ?? cookies.promo,
  referral_code: cookies.rc,
  utm_campaign_id: cookies.utm_campaign_id?.toString(),
  utm_ad_group: cookies.utm_ad_group?.toString(),
  utm_ad_group_id: cookies.utm_ad_group_id?.toString(),
  utm_ad: cookies.utm_ad?.toString(),
  utm_ad_id: cookies.utm_ad_id?.toString(),
  utm_placement: cookies.utm_placement,
  utm_cta_offer_text: cookies.utm_cta_offer_text,

  ramp_attid: cookies.initial_ramp_attid ?? cookies.ramp_attid,
  li_fat_id: cookies.initial_li_fat_id ?? cookies.li_fat_id,
  gclid: cookies.initial_gclid ?? cookies.gclid,
  gclid_ts: cookies.initial_gclid_ts ?? cookies.gclid_ts,
  fbp: cookies._fbp,
  fbc: cookies._fbc,
  fbc_ts: cookies._fbc_ts,
  msclkid: cookies.initial_msclkid,
  msclkid_ts: cookies.initial_msclkid_ts,
  ttclid: cookies.initial_ttclid,
  ttclid_ts: cookies.initial_ttclid_ts,
  impact_crm_clickid: cookies.initial_irclickid,
  impact_crm_clickid_ts: cookies.initial_irclickid_ts ?? cookies.irclickid_ts,
  fintel_clickid: cookies.initial_ftclick?.toString(),
  fintel_clickid_ts: cookies.initial_ftclick_ts,
  discovery_method: cookies.initial_discovery_method,
  referral_location: cookies.initial_referral_location ?? cookies.referral_location,
  pricing_tier: cookies.pricing_plan ?? cookies.initial_pricing_plan,
})

export const useCreateLead = () => {
  const createLead = useCreateLeadModel()
  const updateLead = useUpdateLeadModel()
  const { setIsLeadHydrated } = useContext(LeadContext)

  return useCallback(
    async (
      email: string,
      customLead?: CustomLeadProperties,
      customSegment?: CustomSegmentProperties
    ): Promise<ApiLeadModel | undefined> => {
      const cookies = CookiesUtil.getAll()
      const leadProperties = getLeadProperties({ email, cookies })

      const response = await createLead({ ...leadProperties, ...customLead })
      if (!response) {
        return undefined
      }

      setIsLeadHydrated(true)

      const partnerPromo = cookies.partner_promo ?? cookies.initial_partner_promo ?? cookies.promo
      const leadUsedPersonalEmail =
        cookies.allow_personal_email_signup ||
        partnerPromo === 'smartbiz' ||
        partnerPromo === 'live-oak-bank' ||
        partnerPromo === 'grasshopper-bank' ||
        partnerPromo === 'frb' ||
        partnerPromo === 'wells-fargo' ||
        partnerPromo === 'firstbase'

      // Identifying with UTM props so they are added
      // to segment user traits and not inferred through presence on page/track events
      await window.analytics.identify(
        {
          email,
          initial_utm_campaign: leadProperties.initial_utm_campaign,
          initial_utm_source: leadProperties.initial_utm_source,
          initial_utm_medium: leadProperties.initial_utm_medium,
          initial_utm_term: leadProperties.initial_utm_term,
          initial_utm_content: leadProperties.initial_utm_content,
          initial_promo: leadProperties.initial_promo,
          initial_referral_code: leadProperties.initial_referral_code,
          initial_utm_campaign_id: leadProperties.initial_utm_campaign_id,
          initial_utm_ad_group: leadProperties.initial_utm_ad_group,
          initial_utm_ad_group_id: leadProperties.initial_utm_ad_group_id,
          initial_utm_ad: leadProperties.initial_utm_ad,
          initial_utm_ad_id: leadProperties.initial_utm_ad_id,
          initial_utm_placement: leadProperties.initial_utm_placement,
          initial_utm_cta_offer_text: leadProperties.initial_utm_cta_offer_text,
          last_utm_campaign: leadProperties.utm_campaign,
          last_utm_source: leadProperties.utm_source,
          last_utm_medium: leadProperties.utm_medium,
          last_utm_term: leadProperties.utm_term,
          last_utm_content: leadProperties.utm_content,
          last_promo: leadProperties.promo,
          last_referral_code: leadProperties.referral_code,
          last_utm_campaign_id: leadProperties.utm_campaign_id,
          last_utm_ad_group: leadProperties.utm_ad_group,
          last_utm_ad_group_id: leadProperties.utm_ad_group_id,
          last_utm_ad: leadProperties.utm_ad,
          last_utm_ad_id: leadProperties.utm_ad_id,
          last_utm_placement: leadProperties.utm_placement,
          last_utm_cta_offer_text: leadProperties.utm_cta_offer_text,
          gclid: leadProperties.gclid,
          ramp_attid: leadProperties.ramp_attid,
          discovery_method: leadProperties.discovery_method,
        },
        {
          context: {
            externalIds: [
              {
                id: response.id,
                type: 'lead_id',
                collection: 'users',
                encoding: 'none',
              },
            ],
          },
        }
      )

      // On web app initiation user() is not always available so we have to wait for .ready()
      window.analytics.ready(() => {
        updateLead(response.id, {
          segment_device_uuid: window.analytics.user().anonymousId(),
        })
      })

      window.analytics.track('Lead Created', {
        fbp: cookies._fbp,
        fbc: cookies._fbc,
        email,
        ...customSegment,
      })

      if (leadUsedPersonalEmail) {
        window.analytics.track('Lead Used Personal Email', {
          fbp: cookies._fbp,
          fbc: cookies._fbc,
          email,
          ...customSegment,
        })
      }

      window.analytics.track('Clearbit Enriched Prequal Completed', {
        email,
        skipped_prequal: response.skipped_prequal,
        ...customSegment,
      })

      LogRocket.identify(response.id, {
        email,
      })

      CookiesUtil.set('leadId', response.id, { path: '/' })
      if (window.clearbit) {
        window.clearbit.identify(response.id, {
          email,
          stage: PreApplicationStep.COMPANY_SIZE,
        })
      }

      return response
    },
    [createLead, setIsLeadHydrated, updateLead]
  )
}
