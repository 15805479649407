import styled from 'styled-components'

import { Form, Field } from 'react-final-form'
import { Fragment, FunctionComponent, useCallback } from 'react'
import g2Logo from '~/src/images/social-proof/g2-logo.svg'

import { RyuBreak, RyuButton, RyuFlex, RyuInputText, RyuModal, RyuText } from '@ramp/ryu'
import { combine, email, required } from 'redux-form-validators'
import { BUSINESS_EMAIL_ERROR, getPersonalEmailError, getEmailDomain } from '~/src/utils/emails'
import { trackEvent } from '~/src/utils/analytics'

type Props = {
  onSubmit: (email: string) => void
  open: boolean
}

type GetStartedEmailForm = {
  email_address: string
}

const FlexContentContainer = styled.div`
  width: 100%;
  max-width: 24.5rem;
  margin: 0 auto;
  padding: 1.5rem 0;
`
const G2ReviewContainer = styled.div`
  width: fit-content;
  padding: 4px 12px 4px 4px;
  display: flex;
  border: 1px solid #dadada;
  border-radius: 100px;
  gap: 8px;
`

export const EmailGate: FunctionComponent<Props> = ({ onSubmit, open: isModalOpen = true }) => {
  const handleSubmit = useCallback(
    (data: GetStartedEmailForm) => {
      const email = data.email_address
      trackEvent('Email Form Submitted', {
        email,
        pathname: '/explore-product',
        discovery_method: 'self_serve_product_demo',
        cvr_component: 'email_gate',
      })
      onSubmit(email)
    },
    [onSubmit]
  )

  return (
    <RyuModal isOpen={isModalOpen} size='m' closable={false}>
      <FlexContentContainer>
        <RyuFlex alignItems='center' justifyContent='center'>
          <G2ReviewContainer>
            <img width={24} height='auto' src={g2Logo} alt='G2 5.0 rating' />
            <RyuFlex alignItems='center' justifyContent='center' gapSize='xs'>
              <RyuText.p size='s' color='primary'>
                4.8 stars
              </RyuText.p>
              <RyuText.p size='s' color='hushed'>
                1,900+ reviews
              </RyuText.p>
            </RyuFlex>
          </G2ReviewContainer>
        </RyuFlex>
        <RyuBreak size='l' />
        <RyuText.h1 align='center' size='l' paddingVertical='none'>
          Welcome to Ramp
        </RyuText.h1>
        <RyuBreak size='m' />
        <RyuText size='m' color='hushed' align='center'>
          Explore our product to see how Ramp helps you streamline your financial processes in a single
          platform.
        </RyuText>
        <RyuBreak size='l' />
        <Form
          width='100%'
          maxWidth={480}
          display='flex'
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name='email_address'
                validate={combine(required(), email(), (value) => getPersonalEmailError(value))}
              >
                {({ input, meta }) => (
                  <Fragment>
                    <RyuInputText
                      value={input.value}
                      variant='outlined'
                      label='Work email address*'
                      hasError={meta.error && meta.touched}
                      type='email'
                      onChange={input.onChange}
                    />
                    <RyuBreak size='xs' />
                    <RyuFlex justifyContent='center'>
                      {meta.error && meta.touched && (
                        <RyuText color='destructive' size='xs'>
                          {meta.error === BUSINESS_EMAIL_ERROR
                            ? `Please enter a valid business email (not ${getEmailDomain(input.value)})`
                            : 'Please enter a valid email address.'}
                        </RyuText>
                      )}
                    </RyuFlex>
                  </Fragment>
                )}
              </Field>

              <RyuBreak size='xs' />
              <RyuButton
                variant='prime'
                color='accent'
                type='submit'
                fullWidth={true}
                _textAlign='center'
              >
                Explore product
              </RyuButton>
            </form>
          )}
          onSubmit={handleSubmit}
        />
      </FlexContentContainer>
    </RyuModal>
  )
}
