import { Fragment, FunctionComponent } from 'react'
import { GoogleTagManager } from '~/src/scripts/gtm'
import { isProduction } from '~/src/utils/env'
import { Helmet } from 'react-helmet-async'
import ContactUsSurveyWrapper from './ContactUsSurveyWrapper'
import { RyuThemedSurface } from '@ramp/ryu'

const ContactUs: FunctionComponent = () => {
  return (
    <Fragment>
      {isProduction && <GoogleTagManager />}
      <Helmet>
        {/* Include chilipiper script */}
        <script src='https://js.chilipiper.com/marketing.js' type='text/javascript' />
      </Helmet>
      <RyuThemedSurface theme='ryu-light'>
        <ContactUsSurveyWrapper />
      </RyuThemedSurface>
    </Fragment>
  )
}

export default ContactUs
