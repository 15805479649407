import { Fragment, FunctionComponent } from 'react'
import { GoogleTagManager } from '~/src/scripts/gtm'
import { isProduction } from '~/src/utils/env'
import { Helmet } from 'react-helmet-async'
import { redirectToThanks } from '../BookADemo/redirectUtils'
import ContactSalesWrapper from './ContactSalesWrapper'

const ContactSales: FunctionComponent = () => {
  return (
    <Fragment>
      {isProduction && <GoogleTagManager />}
      <Helmet>
        {/* Include chilipiper script */}
        <script src='https://js.chilipiper.com/marketing.js' type='text/javascript' />
      </Helmet>
      <ContactSalesWrapper onSubmit={redirectToThanks} />
    </Fragment>
  )
}

export default ContactSales
