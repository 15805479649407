import { Fragment, FunctionComponent, useState } from 'react'

import { RyuBreak, RyuButton, RyuFlex, RyuProgressBar, RyuText } from '@ramp/ryu'

import { RigidPageContent } from '~/src/components/RigidPageContent'
import { StepHeader } from '../StepHeader'
import { CheckboxPicker } from '../CheckboxPicker'
import { BackButton } from '../BackButton'

type Props = {
  onChange: (value: any) => void
  onClickedBack: () => void
}

export const InterestedProducts: FunctionComponent<Props> = ({ onChange, onClickedBack }) => {
  const [selectedOptions, setSelectedOptions] = useState(new Set<string>([]))

  return (
    <Fragment>
      <RyuProgressBar value={95} size='l' color='accent' />

      <RigidPageContent>
        <RyuText align='center' color='hushed' size='l'>
          Is Ramp right for your business?
        </RyuText>
        <StepHeader>Which products are you interested in?</StepHeader>
        <RyuBreak size='l' />
        <RyuText align='center' color='hushed'>
          Select all that apply.
        </RyuText>
        <RyuBreak />
        <CheckboxPicker
          selectedOptions={selectedOptions}
          options={[
            {
              label: 'Corporate Cards',
              value: 'corporate_cards',
            },
            {
              label: 'Employee Reimbursements',
              value: 'reimbursements',
            },
            {
              label: 'Bill Payments',
              value: 'bill_payments',
            },
            {
              label: 'Flex (“Extended Payment Terms”)',
              value: 'flex',
            },
            {
              label: 'Procurement',
              value: 'procurement',
            },
          ]}
          onChange={(checked, value) => {
            setSelectedOptions((prev) => {
              const newSelectedOptions = new Set(prev)
              if (checked) {
                newSelectedOptions.add(value)
              } else {
                newSelectedOptions.delete(value)
              }

              return newSelectedOptions
            })
          }}
        />

        <RyuBreak size='xs' />
        <RyuFlex gapSize='s'>
          <BackButton onClick={onClickedBack} />
          <RyuButton
            color='accent'
            variant='prime'
            size='s'
            disabled={selectedOptions.size === 0}
            onClick={() => {
              onChange({
                interested_in_corporate_cards: selectedOptions.has('corporate_cards'),
                interested_in_reimbursements: selectedOptions.has('reimbursements'),
                interested_in_bill_payments: selectedOptions.has('bill_payments'),
                interested_in_flex: selectedOptions.has('flex'),
                interested_in_procurement: selectedOptions.has('procurement'),
              })
            }}
          >
            Next
          </RyuButton>
        </RyuFlex>
      </RigidPageContent>
    </Fragment>
  )
}
