import { Fragment, FunctionComponent } from 'react'

import { RyuBreak, RyuGrid, RyuText, RyuButton, type RyuListViewItemSingleConfig } from '@ramp/ryu'

import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { KenForm } from '~/src/components/ken/KenForm/KenForm'
import { KenFieldText } from '~/src/components/ken/KenForm/KenFieldText'
import { KenFieldSelect } from '~/src/components/ken/KenForm/KenFieldSelect'
import { KenFieldRadioGroup } from '~/src/components/ken/KenForm/KenFieldRadioGroup'
import { DropdownChoices, ContactUsFormValues } from './types'
import { KenFieldTextarea } from '~/src/components/ken/KenForm/KenFieldTextarea'
import {
  BUSINESS_EMAIL_ERROR,
  isValidEmailMessage,
  isValidEmailNoPersonalMessage,
} from '~/src/utils/emails'

const companySizeMap: DropdownChoices[] = [
  {
    label: '1-10 Employees',
    value: '1-10',
  },
  {
    label: '11-50 Employees',
    value: '11-50',
  },
  {
    label: '51-100 Employees',
    value: '51-100',
  },
  {
    label: '101-250 Employees',
    value: '101-250',
  },
  {
    label: '251+ Employees',
    value: '251+',
  },
]

const monthlySpendMap: DropdownChoices[] = [
  {
    label: 'Under $10,000/mo',
    value: '<10k',
  },
  {
    label: '$10,000 - $50,000/mo',
    value: '10k-50k',
  },
  {
    label: '$50,000 - $250,000/mo',
    value: '50k-250k',
  },
  {
    label: 'Over $250,000/mo',
    value: '250k+',
  },
]

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`

const FullWidthForm = styled.form`
  width: 100%;
`

const ContentMinWidthContainer = styled.div`
  /* min-width: 480px; */
  @media (max-width: 767px) {
    min-width: 100%;
  }
  height: 100%;
`

const FlexSurveyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const validateEmail = ({
  email,
  allowPersonalEmails,
}: {
  email: string | null
  allowPersonalEmails: boolean
}) => {
  const errorMessage = allowPersonalEmails
    ? isValidEmailMessage(email)
    : isValidEmailNoPersonalMessage(email)

  if (!email || errorMessage !== BUSINESS_EMAIL_ERROR) {
    return errorMessage
  }

  return `Please enter a valid business email (not ${email.split('@')[1]})`
}

export type Props = {
  companyLogoUrl?: string
  has50k?: boolean | null
  onSubmit: (data: ContactUsFormValues) => void
}
export const ContactUsSurvey: FunctionComponent<Props> = ({ has50k, onSubmit }) => {
  const initialValues = {
    first_name: '',
    last_name: '',
    company_name: '',
    email: '',
    company_size: null,
    monthly_spend: null,
    has_over_50k: null,
    comments: '',
  }
  const form = useForm<ContactUsFormValues>({ defaultValues: initialValues })

  return (
    <ContactUsSurveyLayout>
      <ContentMinWidthContainer>
        <FlexSurveyContainer>
          <KenForm
            form={form}
            render={({ formProps }) => (
              <FullWidthForm {...formProps}>
                <Flex>
                  <KenFieldText<ContactUsFormValues>
                    name='email'
                    type='email'
                    label='Work email'
                    variant='outlined'
                    hasError={!!form.formState.errors.email}
                    rules={{
                      required: true,
                      validate: (email: any) => {
                        return validateEmail({ email, allowPersonalEmails: false })
                      },
                    }}
                  />
                  <RyuBreak size='l' />
                  <RyuGrid columns={2} responsiveColumns={1} gapSize='s'>
                    <KenFieldText<ContactUsFormValues>
                      name='first_name'
                      label='First name'
                      variant='outlined'
                      hasError={!!form.formState.errors.first_name}
                      rules={{ required: true }}
                    />
                    <KenFieldText<ContactUsFormValues>
                      name='last_name'
                      label='Last name'
                      variant='outlined'
                      hasError={!!form.formState.errors.last_name}
                      rules={{ required: true }}
                    />
                  </RyuGrid>

                  <RyuBreak size='l' />
                  <KenFieldText<ContactUsFormValues>
                    name='company_name'
                    label='Company name'
                    variant='outlined'
                    hasError={!!form.formState.errors.company_name}
                    rules={{ required: true }}
                  />

                  <RyuBreak size='l' />
                  <KenFieldText<ContactUsFormValues>
                    name='job_title'
                    label='Job title'
                    variant='outlined'
                    hasError={!!form.formState.errors.job_title}
                    rules={{ required: true }}
                  />

                  <RyuBreak size='l' />

                  <KenFieldSelect<ContactUsFormValues, 'company_size', DropdownChoices>
                    name='company_size'
                    label='Company size'
                    items={companySizeMap}
                    parseItem={parseCompanySizeItem}
                    variant='outlined'
                    hasError={!!form.formState.errors.company_size}
                    hideSearch={true}
                    rules={{ required: true }}
                  />

                  <RyuBreak size='l' />

                  <KenFieldSelect<ContactUsFormValues, 'monthly_spend', DropdownChoices>
                    name='monthly_spend'
                    label='Estimated monthly spend'
                    items={monthlySpendMap}
                    parseItem={parseMonthlySpendItem}
                    variant='outlined'
                    hasError={!!form.formState.errors.monthly_spend}
                    hideSearch={true}
                    rules={{ required: true }}
                  />

                  <RyuBreak size='l' />

                  <KenFieldTextarea<ContactUsFormValues>
                    name='additional_notes'
                    label="Anything else? Tell us more about what you're looking for (optional)"
                    variant='outlined'
                    hasError={!!form.formState.errors.additional_notes}
                    rules={{ required: false }}
                  />

                  <RyuBreak size='l' />

                  <RyuText.p size='xs' color='hushed'>
                    My business has $50,000 in cash across all accounts
                  </RyuText.p>

                  <KenFieldRadioGroup<ContactUsFormValues, 'has_over_50k'>
                    name='has_over_50k'
                    direction='row'
                    items={[true, false]}
                    rules={{ required: true }}
                    parseItem={(value) => ({
                      iconSide: 'right',
                      value,
                      label: value ? 'Yes' : 'No',
                    })}
                  />
                  <RyuBreak size='l' />

                  <RyuButton
                    variant='prime'
                    _textAlign='center'
                    color='accent'
                    type='submit'
                    fullWidth={true}
                  >
                    Submit
                  </RyuButton>
                </Flex>
              </FullWidthForm>
            )}
            onSubmit={onSubmit}
          />
          {has50k === false && (
            <Fragment>
              <RyuBreak size='m' />
              <RyuText.p size='s' color='destructive'>
                Ramp requires $50k GLA. Read more about our requirements{' '}
                <a href='https://support.ramp.com/hc/en-us/articles/7050688872851-Qualifying-and-Applying-to-Ramp'>
                  here
                </a>
                .
              </RyuText.p>
            </Fragment>
          )}
        </FlexSurveyContainer>
      </ContentMinWidthContainer>
    </ContactUsSurveyLayout>
  )
}

function parseCompanySizeItem(company_size: DropdownChoices): RyuListViewItemSingleConfig {
  return {
    label: company_size.label,
    renderItem: () => company_size.label,
  }
}

function parseMonthlySpendItem(monthly_spend: DropdownChoices): RyuListViewItemSingleConfig {
  return {
    label: monthly_spend.label,
    renderItem: () => monthly_spend.label,
  }
}

const ContactUsSurveyLayout = styled.div`
  padding: 72px 64px;
  height: 100%;
  max-width: 768px;
  @media (max-width: 768px) {
    padding: 36px 36px;
  }
  background-color: #fff;
`
