import { RyuLoadingSpinner } from '@ramp/ryu'
import { parse } from 'query-string'
import { FunctionComponent, useCallback, useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useCreateLead } from '~/src/hooks/leads'
import { LeadContext, RoutingDirection, useFetchLeadModel } from '~/src/models/leads'
import pollPromise from '~/src/utils/poll'
import { StepWrapperAsFullPage } from '../../UserQualification/StepWrapperAsFullPage'
import styled from 'styled-components'
import { isAnyOf } from '~/src/utils/compare'
import { bookingLink } from '../bookingRouteURLs'
import { redirectToDemoFallback } from '../redirectUtils'

const CenteredChildren = styled.div`
  margin: auto;
  height: 100%;
  padding-top: 50%;
  transform: translateY(-50%);
`

const ScoreLead: FunctionComponent = () => {
  const location = useLocation()
  const { search } = location
  const { email: queryStringEmail, force: qsScoringOverride } = parse(search)

  // If a user visits prequal directly with a + sign in email, decoder converts it to a space, so we need to convert it back here
  const email = (queryStringEmail as string)?.replace(' ', '+') ?? undefined

  const createLead = useCreateLead()

  const { lead, setLead, setHydratedLead } = useContext(LeadContext)
  const fetchLeadModel = useFetchLeadModel()
  const history = useHistory()

  // function to generate a lead and poll the BE until the lead is created
  const generateLeadAndPollForCreation = useCallback(
    async (email: string) => {
      // make a partial lead
      const partialLead = await createLead(email, { discovery_method: 'see_a_demo' })
      if (!partialLead) {
        throw new Error('Lead creation failed')
      }

      const leadId = partialLead.id
      // Poll for lead creation
      // This will error if we try more than 5 times
      // This error will bubble up and be caught in the catch block
      const lead = await pollPromise(() => fetchLeadModel(leadId), {
        interval: 1000,
        maxAttempts: 5,
        condition: (lead) => !!lead?.routing_direction,
      })

      return lead
    },
    [createLead, fetchLeadModel]
  )

  // On load of the page, generate a lead and poll for its creation
  useEffect(() => {
    if (!email || typeof email !== 'string') {
      return
    }

    ;(async () => {
      if (qsScoringOverride === 'true') {
        setLead({
          company_email: email,
          routing_direction: RoutingDirection.SALES_QUALIFIED,
          id: 'override',
        })
        return
      }

      try {
        const partialLead = await generateLeadAndPollForCreation(email)
        // manually set the email property of the lead
        setHydratedLead({
          ...partialLead,
          company_email: email,
        })
      } catch (e) {
        // redirect if the lead creation fails
        redirectToDemoFallback(email)
      }
    })()
  }, [generateLeadAndPollForCreation, qsScoringOverride, email, setLead, setHydratedLead])

  // Once the lead has been generated, redirect based on the score
  useEffect(() => {
    if (
      isAnyOf(lead?.routing_direction, RoutingDirection.SALES_QUALIFIED, RoutingDirection.SALES_OPTIONAL)
    ) {
      const params = new URLSearchParams({
        email,
        force: 'true',
      })
      // Lead qualified for direct booking
      history.push(`${bookingLink}?${params.toString()}`)
    } else if (lead?.routing_direction) {
      // Lead not qualified for direct booking
      redirectToDemoFallback(lead.company_email)
    }
  }, [history, lead, lead?.id, lead?.routing_direction, email])

  return (
    <StepWrapperAsFullPage wrapInRigidPage={true}>
      <CenteredChildren>
        <RyuLoadingSpinner size='xxl' />
      </CenteredChildren>
    </StepWrapperAsFullPage>
  )
}

export default ScoreLead
