import { noop } from 'lodash-es'
import { useRef } from 'react'
import {
  type ControllerRenderProps,
  type FieldPath,
  type FieldValues,
  type UseControllerProps,
  useController,
} from 'react-hook-form'

import {
  type KeyboardShortcutCallback,
  RyuInputTextarea,
  type RyuInputTextareaProps,
  useCombinedRef,
  useKeyboardShortcut,
} from '@ramp/ryu'

import { type KenFieldGetCaption, getKenFieldCaption } from './utils'

type KenFieldTextareaProps<
  TFieldValues extends FieldValues = never,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  emptyFieldLabel?: string
  getCaption?: KenFieldGetCaption
  onBeforeChange?: RyuInputTextareaProps['onChange']
  onChange?: RyuInputTextareaProps['onChange']
  onCommandEnter?: KeyboardShortcutCallback
} & UseControllerProps<TFieldValues, TName> &
  Omit<RyuInputTextareaProps, Exclude<keyof ControllerRenderProps<TFieldValues, TName>, 'onBlur'>>

export function KenFieldTextarea<
  TFieldValues extends FieldValues = never,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  emptyFieldLabel,
  caption,
  getCaption,
  required,
  onBeforeChange,
  onChange,
  onCommandEnter = noop,
  onBlur,
  ...restProps
}: KenFieldTextareaProps<TFieldValues, TName>) {
  const {
    field,
    fieldState: { error },
  } = useController<TFieldValues, TName>({ ...restProps, rules: { required, ...restProps.rules } })

  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const combinedRef = useCombinedRef<HTMLTextAreaElement>(field.ref, textareaRef)

  const isNullishValue = !field.value?.length

  useKeyboardShortcut(['command', 'enter'], onCommandEnter, {
    scopeElement: textareaRef.current,
  })

  return (
    <RyuInputTextarea
      {...restProps}
      {...field}
      ref={combinedRef}
      label={isNullishValue ? emptyFieldLabel ?? label : label}
      caption={getKenFieldCaption({ error, caption, getCaption })}
      required={required}
      hasError={!!error}
      onChange={(nextValue, meta) => {
        onBeforeChange?.(nextValue, meta)

        if (meta.event.isDefaultPrevented()) {
          return
        }

        field.onChange(nextValue)
        onChange?.(nextValue, meta)
      }}
      onBlur={(event) => {
        onBlur?.(event)
        field.onBlur()
      }}
    />
  )
}
