import {
  type ControllerRenderProps,
  type FieldPath,
  type FieldValues,
  type UseControllerProps,
  useController,
} from 'react-hook-form'

import { RyuInputText, type RyuInputTextProps } from '@ramp/ryu'

import { KenInputPassword } from './KenInputPassword'
import { type KenFieldGetCaption, getKenFieldCaption } from './utils'

type KenFieldTextProps<
  TFieldValues extends FieldValues = never,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  emptyFieldLabel?: string
  getCaption?: KenFieldGetCaption
  onBeforeChange?: RyuInputTextProps['onChange']
  onChange?: RyuInputTextProps['onChange']
} & UseControllerProps<TFieldValues, TName> &
  Omit<RyuInputTextProps, Exclude<keyof ControllerRenderProps<TFieldValues, TName>, 'onBlur'>>

export function KenFieldText<
  TFieldValues extends FieldValues = never,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  emptyFieldLabel,
  caption,
  getCaption,
  required,
  onBeforeChange,
  onChange,
  onBlur,
  ...restProps
}: KenFieldTextProps<TFieldValues, TName>) {
  const {
    field,
    fieldState: { error },
  } = useController<TFieldValues, TName>({ ...restProps, rules: { required, ...restProps.rules } })

  const isNullishValue = !field.value?.length

  const commonProps: RyuInputTextProps = {
    ...restProps,
    ...field,
    label: isNullishValue ? emptyFieldLabel ?? label : label,
    caption: getKenFieldCaption({ error, caption, getCaption }),
    required,
    hasError: !!error,
    onChange: (nextValue, meta) => {
      onBeforeChange?.(nextValue, meta)

      if (meta.event.isDefaultPrevented()) {
        return
      }

      field.onChange(nextValue)
      onChange?.(nextValue, meta)
    },
    onBlur: (event) => {
      onBlur?.(event)
      field.onBlur()
    },
  }

  if (restProps.type === 'password') {
    return <KenInputPassword {...commonProps} />
  }

  return <RyuInputText {...commonProps} />
}
