import { FunctionComponent, useContext, useEffect, useMemo, useRef } from 'react'
import { pageProperties } from '~/src/utils/analytics/pageEnrichment'

import {
  RyuBreak,
  RyuGrid,
  RyuText,
  RyuButton,
  RyuInputText,
  RyuPad,
  RyuFlex,
  useRyuBreakpoints,
  RyuImage,
  RyuIcon,
  useRyuThemeState,
} from '@ramp/ryu'
import { Form, Field } from 'react-final-form'

import { FullScreenConfetti } from '../FullScreenConfetti'
import styled from 'styled-components'
import { LeadContext, LeadExperiment, LeadModel } from '~/src/models/leads/LeadModel'
import { required } from 'redux-form-validators'
import { trackEvent } from '~/src/utils/analytics'
import contactSalesAvatars from '~/src/images/contact-sales-avatars.png'
import customerLogoFooter from '~/src/images/customer-logo-footer.png'
import customerLogoFooterDark from '~/src/images/customer-logo-footer-dark.png'

import contactSalesAvatarStack from '~/src/images/contact-sales-avatar-stack.png'
import { KenStack } from '~/src/components/ken/KenStack'
import { useQualified } from '~/src/hooks/useQualified'
import { TalkToSalesButton } from '~/src/components/ChatIconWithGreenDot'

export type SalesDemoForm = {
  first_name: string
  last_name: string
  company: string
}

type Props = {
  onSubmit: (data: SalesDemoForm) => void
  onOverride: () => void
  bookedMeeting?: boolean
}

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`

export const SalesDemo: FunctionComponent<Props> = ({ onSubmit, onOverride, bookedMeeting }) => {
  const { lead, isLeadHydrated } = useContext(LeadContext)

  const [theme] = useRyuThemeState()

  const { initExperimentGroup } = useQualified()

  // Runs useEffect only use even on React strict mode
  const experimentInitializedRef = useRef(false)

  useEffect(() => {
    if (lead && isLeadHydrated && !isQualifiedExperimentSet(lead) && !experimentInitializedRef.current) {
      initExperimentGroup()
      experimentInitializedRef.current = true
    }
  }, [initExperimentGroup, isLeadHydrated, lead])

  useEffect(() => {
    trackEvent('Contact Sales Page Viewed', { email: lead?.company_email ?? '', ...pageProperties() })
    trackEvent('SS CS DR VE Total Page Viewed', {
      email: lead?.company_email ?? '',
      ...pageProperties(),
    })
  }, [lead?.company_email])

  const initialValues = useMemo(
    () => ({
      first_name: lead?.first_name ?? '',
      last_name: lead?.last_name ?? '',
      company: lead?.company_name ?? '',
    }),
    [lead]
  )

  const isDesktop = useRyuBreakpoints({ minWidth: 'desktop' })

  const isTablet = useRyuBreakpoints({ maxWidth: 'tablet' })

  if (bookedMeeting) {
    onOverride()
  }

  return (
    <SalesDemoLayout>
      <FullScreenConfetti />
      <RyuFlex gapSize='xl' alignItems='center' responsive={true}>
        <RyuPad size='l'>
          <div>
            {!isDesktop && <ContactSalesAvatarStack src={contactSalesAvatarStack} />}

            <RyuText.h2 align={isDesktop ? 'center' : 'left'} size='l' paddingVertical='none'>
              Let's get you connected with a Ramp expert
            </RyuText.h2>

            <RyuBreak size='s' />

            <RyuText.p align={isDesktop ? 'center' : 'left'} color='hushed'>
              See the full power of the Ramp platform in 30 minutes
            </RyuText.p>

            <RyuBreak />

            {lead?.company_email && (
              <RyuInputText
                hasBorder={true}
                label='Email'
                value={lead?.company_email ?? ''}
                disabled={true}
              />
            )}

            <Form
              initialValues={initialValues}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Flex>
                    <RyuBreak size='s' />

                    <RyuGrid columns={2} responsiveColumns={1} gapSize='s'>
                      <Field name='first_name' validate={required()}>
                        {({ input, meta }) => (
                          <RyuInputText
                            value={input.value}
                            hasBorder={true}
                            label='First Name'
                            hasError={meta.error && meta.touched}
                            onChange={input.onChange}
                          />
                        )}
                      </Field>
                      <Field name='last_name' validate={required()}>
                        {({ input, meta }) => (
                          <RyuInputText
                            value={input.value}
                            hasBorder={true}
                            label='Last Name'
                            hasError={meta.error && meta.touched}
                            onChange={input.onChange}
                          />
                        )}
                      </Field>
                    </RyuGrid>

                    <RyuBreak size='s' />

                    <Field name='company' validate={required()}>
                      {({ input, meta }) => (
                        <RyuInputText
                          value={input.value}
                          hasBorder={true}
                          label='Company Name'
                          hasError={meta.error && meta.touched}
                          onChange={input.onChange}
                        />
                      )}
                    </Field>

                    <RyuBreak size='s' />

                    <RyuButton
                      variant='prime'
                      color='accent'
                      type='submit'
                      fullWidth={true}
                      _textAlign='center'
                    >
                      Book a demo
                    </RyuButton>
                  </Flex>
                </form>
              )}
              onSubmit={onSubmit}
            />

            <RyuBreak size='xl' />

            <RyuFlex justifyContent='center'>
              <RyuFlex direction='row' gapSize='m' alignItems='center'>
                <RyuText color='hushed' align='center'>
                  Don’t need a demo? Ready to apply?
                </RyuText>

                <RyuButton
                  fullWidth={true}
                  iconSide='right'
                  iconType='arrow-right'
                  href='/sign-up'
                  _textAlign='center'
                >
                  Apply for Ramp
                </RyuButton>
              </RyuFlex>
            </RyuFlex>
          </div>
        </RyuPad>

        <RyuPad size='l'>
          {isDesktop && <RyuImage src={contactSalesAvatars} />}

          <RyuBreak as='hr' size='xl' />

          <RyuText.h4 paddingVertical='none'>What can you expect?</RyuText.h4>

          <RyuBreak size='l' />

          <KenStack gapSize='m'>
            <RyuFlex gapSize='s'>
              <RyuIcon fill={true} type='help-circle' size='l' />
              <RyuText.span>Answers to any questions you have about Ramp</RyuText.span>
            </RyuFlex>

            <RyuFlex gapSize='s'>
              <RyuIcon fill={true} type='dollar-sign' size='l' />
              <RyuText.span>A custom assessment to see how much Ramp will save you</RyuText.span>
            </RyuFlex>

            <RyuFlex gapSize='s'>
              <RyuIcon fill={true} type='merge' size='l' />
              <RyuText.span>Information on how Ramp can consolidate your financial systems</RyuText.span>
            </RyuFlex>

            {isTablet && <TalkToSalesButton fullWidth={true} _textAlign='center' />}
          </KenStack>
        </RyuPad>
      </RyuFlex>

      <RyuBreak size='xl' />

      {isDesktop && (
        <RyuPad size='s'>
          <RyuImage src={theme === 'ryu-dark' ? customerLogoFooterDark : customerLogoFooter} />
        </RyuPad>
      )}
    </SalesDemoLayout>
  )
}

const SalesDemoLayout = styled.div`
  max-width: 90rem;
  margin: 0 auto;
  height: calc(100vh - 10rem);
  position: relative;
`

const ContactSalesAvatarStack = styled.img`
  max-width: 10rem;
`

function isQualifiedExperimentSet(lead: LeadModel) {
  if (!lead?.experiments) {
    return false
  }

  return (
    lead.experiments.includes(LeadExperiment.QualifiedChatBotControl) ||
    lead.experiments.includes(LeadExperiment.QualifiedChatBotTreatment)
  )
}
