import styled from 'styled-components'

import { ComponentPropsWithoutRef, FunctionComponent } from 'react'

import { RyuLoadingSpinner } from '@ramp/ryu'

const CenterInPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
`

export const Loading: FunctionComponent<ComponentPropsWithoutRef<typeof CenterInPage>> = (props) => (
  <CenterInPage {...props}>
    <RyuLoadingSpinner size='xxl' color='rainbow' />
  </CenterInPage>
)
